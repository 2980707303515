import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth0 } from "@auth0/auth0-react";
import "./ImageGrid.css";
import { FaHeart } from "react-icons/fa";

const ImageComponent = ({
  image,
  imageIds,
  handleImageDoubleClick,
  onFavoriteClick,
  setSelectedImage,
  showNSFW,
}) => {
  const { isAuthenticated, user } = useAuth0();
  const isFavorite = imageIds.includes(image.id);
  const isNSFW = image.url.includes("-2");
  // const AdProvider = "0";

  const handleHeartClick = (e) => {
    e.stopPropagation();
    if (user) {
      onFavoriteClick(user.sub, image.id);
    }
  };
  console.log("showNSFW", showNSFW)
  console.log("Auth", isAuthenticated)
  console.log("isNSFW", isNSFW)
  const handleImageClick = () => {
    setSelectedImage(image);
  };
  return (
<div className="image-container">
<div
    className={`blur-wrapper ${isNSFW && (!showNSFW || !isAuthenticated) ? 'active' : ''}`} 
    onClick={handleImageClick}
  >
    {isNSFW && !showNSFW && !isAuthenticated && (
      <div className="overlay-text">Login to view NSFW</div>
    )}
    <img
      src={image.url}
      alt={`${image.id}`}
      className={`blur-image ${isNSFW && (!showNSFW || !isAuthenticated) ? 'active' : ''}`} 
      onDoubleClick={() => handleImageDoubleClick(image.id)}
    />
  </div>
      {isAuthenticated && (
        <FaHeart
          className={`heart-icon ${isFavorite ? "favorite" : ""}`}
          onClick={handleHeartClick}
          onDoubleClick={handleHeartClick} // This can be added if you want double-click on heart to also toggle favorite
        />
      )}
    </div>
  );
};

const ImageGrid = ({ showFavoritesOnly, showNSFW, toggleNSFWVisibility }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const [imageIds, setFavorites] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchImages = async () => {
    if (!hasMore) return;
    try {
      const response = await fetch(
        `https://api.latestmuscle.com/api/images?page=${page}&limit=20`
      );
      const newImages = await response.json();

      // Sort images based on ID in descending order (higher ID first)
      newImages.sort((a, b) => b.id - a.id);

      setImages((prevImages) => [...prevImages, ...newImages]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(newImages.length === 20);
    } catch (error) {
      console.error("Error fetching images:", error);
      setHasMore(false);
    }
  };

  const handleAuthentication = async () => {
    try {
      const token = await getAccessTokenSilently();
      await fetch("https://api.latestmuscle.com/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ auth0Id: user.sub }),
      });
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  const getFavorites = async (userId) => {
    try {
      const response = await fetch(
        `https://api.latestmuscle.com/favorites/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const favoritesArray = await response.json();
      const imageIds = favoritesArray[0]?.image_ids || [];
      setFavorites(imageIds);
      return imageIds;
    } catch (error) {
      console.error("Error retrieving user favorites:", error);
    }
  };

  const fetchFavoriteImages = async () => {
    if (!imageIds.length) {
      return;
    }

    // Fetch image data for each ID
    const fetchPromises = imageIds.map((imageId) =>
      fetch(`https://api.latestmuscle.com/api/images/${imageId}`)
        .then((response) => {
          if (!response.ok) {
            console.error(`Failed to fetch image with ID ${imageId}`);
            return null; // Return null for failed requests
          }
          return response.json();
        })
        .catch((error) => {
          console.error(`Error fetching image with ID ${imageId}:`, error);
          return null; // Return null for errors
        })
    );

    try {
      const imagesData = await Promise.all(fetchPromises);
      const validImages = imagesData.filter((image) => image !== null); // Filter out null values
      setImages(validImages); // Update state with valid images only
    } catch (error) {
      console.error("Error processing favorite images:", error);
    }
  };

  const handleImageDoubleClick = (imageId) => {
    if (user) {
      onFavoriteClick(user.sub, imageId);
    }
  };

  const onFavoriteClick = async (userId, imageId) => {
    try {
      const imageIdInt = parseInt(imageId, 10);
      if (isNaN(imageIdInt)) {
        throw new Error("Invalid imageId");
      }

      const endpoint = imageIds.includes(imageIdInt) ? "/remove" : "/add";

      const response = await fetch(
        `https://api.latestmuscle.com/favorites${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, imageId: imageIdInt }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const favorite = await response.json();
      setFavorites(favorite.image_ids); // Update the favorites state
    } catch (error) {
      console.error("Error updating favorite:", error);
    }
  };

  // function insertAd() {
  //   if (window.AdProvider && window.AdProvider.isInitialized()) { 
  //     (AdProvider = window.AdProvider || []).push({"serve": {}}); // Or the correct API usage
  //   } 
  // };

//   const MyComponent = () => {
//     useEffect(() => { 
//       const script = document.createElement('script');
//       script.src = "https://a.magsrv.com/ad-provider.js";
//       script.async = true;
//       script.onload = insertAd; // Run after ad-provider.js loads and initializes
    
//       document.body.appendChild(script);
//       return () => document.body.removeChild(script); // Cleanup on component unmount
//     }, []);
// };

  const navigateImage = useCallback(
    (direction) => {
      const currentIndex = images.findIndex(
        (img) => img.id === selectedImage.id
      );
      const newIndex =
        (currentIndex + direction + images.length) % images.length;
      setSelectedImage(images[newIndex]); // Update the selected image
    },
    [images, selectedImage, setSelectedImage]
  );

  const handleArrowClick = (direction, e) => {
    e.stopPropagation();
    navigateImage(direction);
  };

  useEffect(() => {
    console.log("useEffect: showFavoritesOnly", showFavoritesOnly);
    console.log("useEffect: isAuthenticated", isAuthenticated);
    console.log("useEffect: imageIds", imageIds);
    if (showFavoritesOnly && isAuthenticated && imageIds.length) {
      fetchFavoriteImages();
    } else if (!showFavoritesOnly) {
      fetchImages();
    }
  }, [showFavoritesOnly, isAuthenticated, imageIds]);

  useEffect(() => {
    if (isAuthenticated && user) {
      getFavorites(user.sub);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!selectedImage) return;

      if (e.key === "ArrowLeft") {
        navigateImage(-1);
      } else if (e.key === "ArrowRight") {
        navigateImage(1);
      }
    };

    if (selectedImage) {
      document.body.style.overflow = "hidden";
      window.addEventListener("keydown", handleKeyPress);
    } else {
      document.body.style.overflow = "";
      window.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedImage, navigateImage]);

  return (
    <InfiniteScroll
      dataLength={images.length}
      next={fetchImages}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
    >
      <div className="image-grid">
        {images.map((image, index) => (
          <React.Fragment key={`fragment-${image.id}`}>
            <div key={`image-${image.id}`}>
              <ImageComponent
                image={image}
                imageIds={imageIds}
                userId={user?.sub}
                handleImageDoubleClick={handleImageDoubleClick}
                onFavoriteClick={onFavoriteClick}
                setSelectedImage={setSelectedImage}
                showNSFW={showNSFW}
              />
            </div>
            {index % 12 === 11}
          </React.Fragment>
        ))}
      </div>
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}> {/* Prevent onClick from triggering on the modal content */}
            <img src={selectedImage.url} alt={selectedImage.id} />
            {/* Include any additional details you want to show for the selected image */}
          </div>
        </div>
      )}
    </InfiniteScroll>
  );
};

export default ImageGrid;
